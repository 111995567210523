import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const query = graphql`
  {
    strapiTestimonials {
      subTitle
      title
      longDesc
      item {
        id
        name
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        feedbackText
        designation
      }
    }
  }
`

const TestimonialsStyleOne = () => {
  const data = useStaticQuery(query)
  const {
    strapiTestimonials: { subTitle, title, longDesc, item },
  } = data

  return (
    <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="testimonial" />
            {subTitle}
          </span>
          <h2>{title}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
        </div>

        <div className="row">
          {item.map(list => {
            const {
              name,
              designation,
              feedbackText,
              image: {
                localFile: {
                  childImageSharp: {
                    original: { src: userImage },
                  },
                },
              },
            } = list
            return (
              <div className="col-lg-6 col-md-6" key={list.id}>
                <div className="single-testimonials-item">
                  <p>{feedbackText}</p>
                  <div className="client-info">
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={userImage} alt="Client" />
                      <div className="title">
                        <h3>{name}</h3>
                        <span>{designation}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TestimonialsStyleOne
