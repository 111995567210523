import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import TestimonialsStyleOne from "../components/Testimonials/TestimonialsStyleOne"
import Seo from "../components/App/Seo"
import { graphql } from "gatsby"

const Testimonials = ({ data }) => {
  const { metaTags } = data
  const { metaImage } = metaTags
  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "testimonials"
  })[0]

  return (
    <Layout>
      <Seo
        url={"testimonials"}
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
      />
      <Navbar />
      <PageBanner
        pageTitle="Testimonials"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Testimonials"
      />
      <TestimonialsStyleOne />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHomeDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
  }
`
export default Testimonials
